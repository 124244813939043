
import config from './config';

let data = {
    hash: null,
    arena_config: [],
    arenas: [],
    live_poll_voted: [],
    session_hid: '',
    sessions: 0,
    login: null,
    config: null,
    message: '',
    content: '',
    extra: '',
    realtime: null
};

if (sessionStorage.getItem("data")) {
    data = JSON.parse(sessionStorage.getItem("data"));
}

export default class Core {

    // App overrides this in your constructor to be called after the login
    // to be able to switch the Login/Logout menu option.
    static onLogged = () => { };

    static menu = {
        click: () => {
            window.scrollTo(0, 0);
        }
    };

    static data() {
        return data;
    }

    static configAPIPath() {
        return config.api.services;
    }

    static apiData(path, settings) {
        const success = settings.onSuccess ? settings.onSuccess : () => {};
        const fail = settings.onFail ? settings.onFail : () => {};
        fetch(config.api.data + path + "?time="+ new Date().getTime(), {
            method: settings.method ? settings.method : 'GET',
            credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                "Accept":  'application/json'
            }
        }).then(
            (response) => {
                if (response.ok) {
                    if (response.status == 204) {
                        return success();
                    } else {
                        const contentType = response.headers.get("Content-Type");
                        if (contentType && contentType.toLowerCase().indexOf("application/json") == 0) {
                            return response.json().then(function(data) {
                                success({ json: data });
                            });
                        } else {
                            return response.text().then(function(text) {
                                success({ text: text });
                            });
                        }
                    }
                } else {
                    return fail({ response: response });
                }
            }
        ).catch((e) => {
            fail({ error: e });
        });
    }

    static apiService(path, settings) {
        const success = settings.onSuccess ? settings.onSuccess : () => {};
        const fail = settings.onFail ? settings.onFail : () => {};
        const configs = {
            method: settings.method ? settings.method : 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                "Accept":  'application/json'
            }
        };
        if (settings.data) {
            configs.body = JSON.stringify(settings.data);
        }
        fetch(config.api.services + path, configs).then(
            (response) => {
                if (response.ok) {
                    if (response.status == 204) {
                        return success();
                    } else {
                        const contentType = response.headers.get("Content-Type");
                        if (contentType && contentType.toLowerCase().indexOf("application/json") == 0) {
                            return response.json().then(function(data) {
                                success({ json: data });
                            });
                        } else {
                            return response.text().then(function(text) {
                                success({ text: text });
                            });
                        }
                    }
                } else {
                    return fail({ response: response });
                }
            }
        ).catch((e) => {
            fail({ error: e });
        });
    }

    static async loadArena(arena) {
        console.log(arena);
        const data = await fetch(config.api.data + `arena/${arena}.json` + "?time="+ new Date().getTime(), {
            method: 'GET',
            credentials: 'include',
            headers: {
                "Content-Type": 'application/json',
                "Accept":  'application/json'
            }
        }).then(
            (response) => {
                if (response.ok) {
                    console.log("OK")
                    if (response.status == 204) {
                        return {}
                    } else {
                        const contentType = response.headers.get("Content-Type");
                        if (contentType && contentType.toLowerCase().indexOf("application/json") == 0) {
                            return response.json().then(function(data) {
                                return { json: data };
                            });
                        } else {
                            return response.text().then(function(text) {
                                return { text: text }
                            });
                        }
                    }
                } else {
                    return { response: response }
                }
            }
        ).catch((e) => {
            return { error: e };
        });

        return data;
    }

    static async sha512(content) {
        const hash = await crypto.digest('SHA-512', content);
        return hash;
    }
}
