import React, { Component } from 'react';

import Zoom from '../../containers/Zoom';
//
import './index.scss';

export default class ZoomPage extends Component {


  componentDidMount() {
    document.getElementById('zmmtg-root').style.display = "block";
  }

  render() {
    return (
      <div>
        <Zoom arenaData={this.props.arenaData} />
      </div>
    );
  }

}
