import React, { Component } from 'react'
import { ZoomMtg } from "@zoomus/websdk";
import Core from "../../Core";
import styles from "./Zoom.scss";
import '@zoomus/websdk/dist/css/bootstrap.css';
import '@zoomus/websdk/dist/css/react-select.css';
//const API_KEY = 'hgnTfZafSy2ayhqwW8Cq9Q';
//const API_SECRET = 'PgwDRrYw7ItIXUBsOiHJG1XY2ALKggECGI4N';
//const MEETING_NUMBER = 94827110177;

let meetConfig = {
  apiKey: '',
  apiSecret: '',
  meetingNumber: 0,
  userName: 'tester',
  userEmail: 'jpcardoso97@gmail.com',
  password: 'aqe0cB',
  leaveUrl: window.location.protocol + '//' + window.location.host + '/player/',
  role: 0
};

export default class Zoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingLaunched: false,
      signature: ''

    };
    this.generateSignatureZoomVersion = this.generateSignatureZoomVersion.bind(this);
    this.launchMeeting = this.launchMeeting.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.playerOption !== this.props.playerOption) {
      if (newProps.playerOption) {
        console.log("generating Signature")

        this.generateSignatureNode();
        document.getElementById('zmmtg-root').style.display = "block";
      } else {
        document.getElementById('zmmtg-root').style.display = "none";
      }
    }
  }


  componentDidMount() {
    const { arenaData } = this.props;
    console.log("this.props");
    console.log(arenaData);

    this.loadArena(arenaData.arena_secret);

    meetConfig.apiKey = Core.data().arena_config.zoom_api_key;
    meetConfig.apiSecret = Core.data().arena_config.zoom_api_secret;
    meetConfig.meetingNumber = Number(Core.data().arena_config.zoom_meeting_id);

    console.log('checkSystemRequirements');
    console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
    ZoomMtg.setZoomJSLib('https://dmogdx0jrul3u.cloudfront.net/1.9.1/lib', '/av');

    //ZoomMtg.setZoomJSLib("https://source.zoom.us/1.7.8/lib", "/av");
    //ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.1/lib', '/av');
    //ZoomMtg.setZoomJSLib('./lib/', '/av')
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    //this.generateSignature();
    this.generateSignatureZoomVersion();
    document.getElementById('zmmtg-root').style.display = "block";

  }

  componentWillUnmount() {
    document.getElementById('zmmtg-root').style.display = "none";
    ZoomMtg.leaveMeeting({});
  }

  loadArena = (arena) => {
    console.log("LoadArena");
    console.log(Core.data());
    Core.apiData(`arena/${arena}.json`, {
        onSuccess: (data) => {
            Core.data().sessions = data.json.sessions;
            if (Core.data().arena_config != data.json) {
                Core.data().arena_config = data.json;
                sessionStorage.setItem("data", JSON.stringify(Core.data()));
            }
        },
        onFail: (data) => {
            console.log("Load Arena", data);
        }
    });
  }

  generateSignatureZoomVersion() {
    const that = this;

    ZoomMtg.generateSignature({
      meetingNumber: meetConfig.meetingNumber,
      apiKey: meetConfig.apiKey,
      apiSecret: meetConfig.apiSecret,
      role: meetConfig.role,
      success: function (res) {
        console.log("res", res);

        setTimeout(() => {
          that.launchMeeting(res.result);
        }, 1000);
      },
    });
  }


  generateSignature() {
    const fail = () => {
      alert("Fail genrating Signature, please check the data")
    };
    Core.apiService(`zoom/generateSignature`, {
      data: {
        //participant_uid: Core.data().login.uid
      },
      onSuccess: (data) => {
        if (data.json) {
          //this.setState({ signature: data.json.signature });
          this.launchMeeting(data.json.signature);
        } else {
          console.log("Signature data is not a JSON!");
          fail();
        }
      },
      onFail: (data) => {
        console.log("Signature data failed.", data);
        fail();
      }
    });
  }

  generateSignatureNode() {
    const fail = () => {
      alert("Fail genrating Signature, please check the data")
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "meetingNumber": meetConfig.meetingNumber, "role": 0 })
    };

    fetch('https:/localhost:9000', requestOptions)
      .then(response => response.json())
      .then(data => {
        this.launchMeeting(data.signature)
      });
  }


  launchMeeting(signature) {

    // change state of meeting
    this.setState({ meetingLaunched: !this.state.meetingLaunched })



    console.log('signature', signature);


const test =           {
            meetingNumber: meetConfig.meetingNumber,
            userName: Core.data() && Core.data().login && Core.data().login.name ? Core.data().login.name : meetConfig.userName,
            signature: signature,
            apiKey: meetConfig.apiKey,
            userEmail: Core.data() && Core.data().login && Core.data().login.email ? Core.data().login.email : meetConfig.userEmail,
            passWord: meetConfig.password,
            success() {
              console.log('join meeting success');
            },
            error(res) {
              console.log(res);
            }
          };

	 console.log(test);

    ZoomMtg.init({
      isSupportAV: true,
      leaveUrl: window.location.protocol + '//' + window.location.host + '/',
      success() {
        ZoomMtg.join(
          {
            meetingNumber: meetConfig.meetingNumber,
            userName: Core.data() && Core.data().login && Core.data().login.name ? Core.data().login.name : meetConfig.userName,
            signature: signature,
            apiKey: meetConfig.apiKey,
            userEmail: Core.data() && Core.data().login && Core.data().login.email ? Core.data().login.email : meetConfig.userEmail,
            passWord: meetConfig.password,
            success() {
              console.log('join meeting success');
            },
            error(res) {
              console.log(res);
            }
          }
        );
      },
      error(res) {
        console.log(res);
      }
    });


    ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
      console.log('inMeetingServiceListener onUserJoin', data);
    });

    ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
      console.log('inMeetingServiceListener onUserLeave', data);
    });

    ZoomMtg.inMeetingServiceListener('onUserIsInWaitingRoom', function (data) {
      console.log('inMeetingServiceListener onUserIsInWaitingRoom', data);
    });

    ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
      console.log('inMeetingServiceListener onMeetingStatus', data);
    });

  }

  render() {
    const { meetingLaunched } = this.state;
    // Displays a button to launch the meeting when the meetingLaunched state is false
    return (
      <>

      </>
    )
  }
}
